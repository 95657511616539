.btn {
	.fa:not(.me-0) {
        margin-right: 0.75rem;
		width: 1.25em;
	}
}

.btn.btn-sm {
	.fa:not(.me-0) {
        margin-right: 0.5rem;
		width: 1.25em;
	}
}


.btn.btn-lg {
	.fa:not(.me-0) {
        margin-right: 1rem;
		width: 1em;
	}
}

.btn-xs, .btn-group-xs > .btn {

	padding: 0.15rem 0.35rem !important;
	font-size: 0.725rem !important;
	line-height: 1.30 !important;
	border-radius: 0.15rem !important;

	.fa:not(.me-0) {
        margin-right: 0.5rem;
		width: 1.25em;
	}
}

.no-user-select { user-select: none; }

.me-2p5 { margin-right: 0.75rem; }

.list-group-item {
	.fa:not(.me-0) {
        margin-right: 1rem;
		width: 1.25em;
	}
}

a.list-group-item:hover { text-decoration: none; }

input:not(.no-fc) { @extend .form-control; }

input.form-check-lg {
	width: 1.25rem !important;
	height: 1.25rem !important;
}

// Remove spinner / arrow increment buttons from input[type=number] fields
input[type=number] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.card-body > .table { @extend .mt-0; }

.table {
	thead { @extend .text-gray-800; }
	td { @extend .align-middle; }
}

.tooltip-inner { max-width: none; }

.table-hover { td { cursor: pointer; } }

.clickable { cursor: pointer; }

.not-clickable { cursor: default; }

body>.container>.row>.col-12>.card {
	@extend .shadow-sm;
}

.dropdown-menu-md { min-width: 300px; }

.dropdown-menu-lg { min-width: 450px; }

.table {
	@extend .mt-0;

	th { @extend .border-top-0; }
}

.field_with_errors {

    input { @extend .is-invalid; }
    label {
        @extend .text-danger;
        font-weight: bold;
    }
}

.input-group-error {
	span.input-group-text { @extend .border-danger; }
	input { @extend .border-danger; }
}

.model-attribute-error-list {
	@extend .text-danger;
	model-attribute-error-message {
		display: block;
	}
}

// Stop bootstrap modals from causing fixed elements to jitter on page: https://stackoverflow.com/a/29475026/511372
body.modal-open {
	overflow-y: scroll !important;
	padding-right: 0 !important;
}

.rounded-bottom-start { border-bottom-left-radius: 0.35rem !important; }
.rounded-bottom-end { border-bottom-right-radius: 0.35rem !important; }

@media screen and (max-width: 667px) {
	.mobile-w-25 { width: 25%; }
	.mobile-w-50 { width: 50%; }
	.mobile-w-50 { width: 50%; }
	.mobile-w-75 { width: 75%; }
	.mobile-w-100 { width: 100%; }
}

@media screen and (min-width: 668px) {
	.desktop-w-25 { width: 25%; }
	.desktop-w-48 { width: 48%; }
	.desktop-w-50 { width: 50%; }
	.desktop-w-75 { width: 75%; }
	.desktop-w-100 { width: 100%; }
}

.fw-badge-md .badge { min-width: 125px; }
.fw-badge-lg .badge { min-width: 155px; }
.fw-badge-xl .badge { min-width: 175px; }
