@use "../guidepost" as guidepost;

.progress-bar {
	background-color: #e9ecef;
	height: 0.75rem;
	position: relative;
	border-radius: 25px;

	span {
		display: block;
		height: 100%;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		position: relative;
		overflow: hidden;
	}
}
