@use "../guidepost";

@media screen and (max-width: 667px) {

	table.mobile-table thead { display: none; }
	table.mobile-table td { display: flex; }

	table.mobile-table td::before {
		content: attr(label);
		width: 120px;
		min-width: 120px;
	}

	.table > :not(:first-child) { border-top: none; }
	table.mobile-table td.row-gap { border-bottom: 5px solid #f2f4fd; }

	.mobile-pagination ul.pagination { flex-wrap: wrap; }
}
