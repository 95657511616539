@use "../guidepost" as guidepost;

.underline-animate {
	color: rgb(108, 117, 125);
	@extend .mb-0;
	@extend .p-2;
	cursor: pointer;
	position: relative;
	text-decoration: none;
	user-select: none;
	text-align: center;
}

.underline-animate.disabled {
	cursor: default;
}

.underline-animate:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	bottom: 5px;
	left: 0;
	visibility: hidden;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

.underline-animated:before {
	visibility: visible;
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
}
