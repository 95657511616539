@use "../guidepost" as guidepost;

.shrink-td {
	width: 1rem;
	white-space: nowrap;
}

.force-wrap {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
}
