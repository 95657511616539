.calendar-component {
	.month-name {
		font-size: 22px;
		text-align: center;
	}

	table {

		width: 100%;

		thead tr th {
			text-align: center;
			font-size: 13px;
			color: hsl(0,0,50%);
			border-bottom: solid 1px hsl(0,0,85%);
			width: 14.2%;
		}

		tbody tr td {
			text-align: center;
			height: 42px;
			width: 14.2%;
		}

		.day.active {
			background-color: #42a5f5;
			color: #ffffff;
			border-radius: 150px;
		}

		.day.out-of-month {
			color: hsl(0,0,70%);
		}

	}

}
