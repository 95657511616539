@use "../guidepost" as guidepost;

.btn-stack {
	@extend .vstack;
	@extend .gap-3;
	@extend .px-3;
	@extend .px-lg-5;
}

.btn:not(.disabled) { 
	.enabled-content { display: inline; }
	.disabled-content { display: none; }
}

.btn.disabled { 
	.enabled-content { display: none; }
	.disabled-content { display: inline; }
}

.option-button {
	@extend .rounded;
	@extend .border;
	@extend .d-flex;
	@extend .flex-row;
	@extend .align-items-center;
	@extend .no-user-select;
	@extend .clickable;
}

.option-button .option-button-icon{
		@extend .ms-auto;
}

.option-button.selected {

	@extend .text-success;
	@extend .border-success;
	@extend .border-2;

	.option-button-icon .selected {
		@extend .d-inline-block;
	}

	.option-button-icon .not-selected {
		@extend .d-none;
	}
}

.option-button:not(.selected) {

	.option-button-icon .selected {
		@extend .d-none;
	}

	.option-button-icon .not-selected {
		@extend .d-inline-block;
	}
}
