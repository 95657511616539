@use "guidepost";

.card-title {
	display: flex;
	align-items: center;
	color: guidepost.$gray-600;
	font-size: 1.35rem;
	.fa { margin-right: 1.5rem; }
}

.table .btn {
	@extend .text-nowrap;
}

body {
	@extend .pb-5;
	background-color: #f2f4fd;
	overflow-y: scroll;
}

a, a:hover {
	text-decoration: none;
}

.fs-1 { font-size: 2.25rem !important; }
.fs-2 { font-size: 2rem !important; }
.fs-3 { font-size: 1.75rem !important; }
.fs-4 { font-size: 1.5rem !important; }
.fs-5 { font-size: 1.25rem !important; }
.fs-6 { font-size: 1.15rem !important; }
.fs-7 { font-size: 1rem !important; }
.fs-8 { font-size: 0.85rem !important; }
.fs-9 { font-size: 0.8rem !important; }
.fs-10 { font-size: 0.75rem !important; }
