$brand-primary: #ff0000;
$dark-gray: #313a46;
$font-family-base: 'Nunito', sans-serif;
$font-family-sans-serif: 'Nunito', sans-serif;
$border-radius: 0.35rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.3rem;
$btn-border-radius: 0.15rem;
$btn-border-radius-lg: 0.15rem;
$btn-border-radius-sm: 0.15rem;
$card-border-radius: 0.35rem;

/* set the overriding variables */
$grid-breakpoints: (
  xs: 0,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1540px,
);

$container-max-widths: (
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1620px,
);

@import "~bootstrap/scss/bootstrap";
@import "extensions/bootstrap_color_extensions";
@import "extensions/bootstrap_extensions";

.fa {
	width: 1.25rem;
	text-align: center;
}
