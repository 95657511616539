@use "../guidepost" as guidepost;

.card-body-header {

	@extend .my-1;

	color: guidepost.$gray-900;

	font-size: 1.25rem;

	>.fa {
		width: 1.25em;
		margin-right: 0.75rem;
	}
}

.card-body-item-name {
	@extend .d-block;
	@extend .d-md-inline-block;
	@extend .mt-2;
	@extend .mt-md-0;
	@extend .ms-md-4;
}
