@use "../guidepost";

.page-header {

	@extend .my-3;
	color: guidepost.$dark-gray;

	font-size: 1.65rem;

	.fa {
		width: 1.25em;
		@extend .me-3;
	}
}

.page-item-name {
	@extend .d-block;
	@extend .d-md-inline-block;
	@extend .mt-2;
	@extend .mt-md-0;
	@extend .ms-md-4;
}
