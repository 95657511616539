@use "../guidepost" as guidepost;

.stubby-card {
	@extend .d-inline-flex;
	@extend .flex-column;
	@extend .flex-lg-row;
	@extend .align-items-center;
	@extend .gap-2;
	@extend .border;
	@extend .rounded;
	@extend .bg-white;
	@extend .shadow-sm;
	@extend .p-2;

	.stubby-card-label {
		@extend .mx-3;
		@extend .text-secondary;

		.fa {
			width: 1.25em;
			@extend .me-2;
		}
	}
}
