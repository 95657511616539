@use "../guidepost" as guidepost;

@media screen and (max-width: 667px) {
	.toast-container {
		position: fixed;
		top: 5.5rem;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		z-index: 10000;
	}

}

@media screen and (min-width: 668px) {
	.toast-container {
		position: fixed;
		top: 5.5rem;
		right: 2.5%;
		//left: 0;
		//right: 0;
		//margin-left: auto;
		//margin-right: auto;
		width: 350px;
		z-index: 10000;
		transition: all 2s ease-in-out;
		animate: all 2s ease-in-out;
	}
}


.toast-container-center {
	position: fixed;
	top: 5.5rem;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 350px;
	z-index: 10000;
}


@media screen and (max-width: 667px) {
	.toast { width: 100%; }
}

@media screen and (min-width: 668px) {
	.toast { width: 350px; }
}

.toast {
	@extend .me-0;

	box-shadow: 0 0 12px rgb(50,50,50);
	transition: all 2s ease-in-out;
	animate: all 2s;

	.toast-header {
		@extend .py-2;
		@extend .px-3;
		@extend .fs-5;
	}

	.toast-body {
		@extend .py-3;
		opacity: 0.95;
	}
}
