@use "../guidepost" as guidepost;

// source: https://stackoverflow.com/questions/65856733/css-circular-progress-bar-adding-second-color
.circle-progress-bar {
  font-size: 0.75em;
  width: 60px;
  height: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position:relative;
  z-index:0;
}

/*
.circle-progress-bar::after {
  content: "%";
  font-size: .45em;
}
*/

.circle-progress-bar:not(.circle-progress-bar-double)::before {
  content: "";
  position: absolute;
  z-index:-1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding:8px;
  background:
    linear-gradient(#f3f3f3, #f3f3f3) content-box, /* this background will be shown if mask is no supported */
    conic-gradient(guidepost.$brand-primary 0 var(--p1), #aaa 0);
	// this calculation allows for a --p2 variable to allow two separate color progress bars in one circle.
    //conic-gradient($brand-primary 0 var(--p1),red calc(var(--p1) + 0.5%) calc(var(--p1) + var(--p2)),#999 0); 
  -webkit-mask:radial-gradient(farthest-side,transparent calc(100% - 8px),#fff calc(100% - 7px));
}

.circle-progress-bar-double::before {
  content: "";
  position: absolute;
  z-index:-1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding:8px;
  background:
    linear-gradient(#f3f3f3, #f3f3f3) content-box, /* this background will be shown if mask is no supported */
    conic-gradient(guidepost.$brand-primary 0 var(--p1), blue calc(var(--p1) + 0.5%) calc(var(--p1) + var(--p2)),#999 0); 
  -webkit-mask:radial-gradient(farthest-side,transparent calc(100% - 8px),#fff calc(100% - 7px));
}
