@use "../guidepost";

$dark-gray: guidepost.$dark-gray;
$text-nav: lighten($dark-gray, 70%);
$nav-text-darken-percentage: 16%;

.navbar {
	height: 70px;
	background-color: $dark-gray;

	li, a, .fa, .navright-item {
		color: $text-nav;
	}

	input:focus {
		@extend .border-0;
		border-color: $dark-gray;
	}

	.input-with-icon {
		input {
			@extend .border-0;
			color: darken($text-nav, $nav-text-darken-percentage) !important;
			background-color: lighten($dark-gray, 12%) !important;

		}

		.input-icon .fa { color: darken($text-nav, $nav-text-darken-percentage) !important; }
	}

	#omnisearch-results .dropdown-item {
		color: $dark-gray
	}

	::placeholder {
		color: darken($text-nav, $nav-text-darken-percentage) !important;
		opacity: 1;
	}

	.gp-nav-link {
		.fa {
			width: 1.25em;
			@extend .me-4;
		}
	}

	.gp-nav-link:hover { color: #ebeef1; }
}

.navtop {
	@extend .d-none;
	@extend .d-lg-flex;
	@extend .flex-row;
	@extend .align-items-center;
	@extend .shadow-sm;
	background-color: #ffffff;

	.navtop-item {

		.gp-nav-link {
			@extend .d-flex;
			@extend .align-items-center;
			@extend .me-4;

			padding: 0.75rem 0;
			cursor: pointer;

			.fa {
				width: 1.25em;
				@extend .me-3;
			}
		}
	}

	.navtop-item a {
		color: lighten($dark-gray, $nav-text-darken-percentage);
	}

	.hover-dropdown {
		position: relative;
		display: inline-block;
	}

	.nav-dropdown-content {
		@extend .vstack;
		@extend .gap-3;
		@extend .p-3;
		@extend .shadow;
		@extend .border;
		@extend .rounded-bottom;
		display: none;
		position: absolute;
		background-color: #ffffff;
		z-index: 1000;
		padding-top: 4px;
		margin-left: -1rem;

	}

	.hover-dropdown a.gp-nav-link:hover { color: $dark-gray; }

	.hover-dropdown:hover .nav-dropdown-content { display: flex; }

	a {
		display: inline-block;
		@extend .text-nowrap;
		.fa {
			@extend .me-3;
			width: 1.25em;
		}
	}
}

.navtop-mobile {
	display: none;
	background-color: #ffffff;

	.gp-nav-link-mobile {
		@extend .py-2;

		.fa {
			width: 1.25em;
			@extend .me-3;
		}
	}
}
