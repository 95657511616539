.text-blue { color: $blue !important; }
.text-indigo { color: $indigo !important; }
.text-purple { color: $purple !important; }
.text-pink { color: $pink !important; }
.text-red { color: $red !important; }
.text-orange { color: $orange !important; }
.text-yellow { color: $yellow !important; }
.text-green { color: $green !important; }
.text-teal { color: $teal !important; }
.text-cyan { color: $cyan !important; }

.text-gray-100 { color: $gray-100 !important; }
.text-gray-200 { color: $gray-200 !important; }
.text-gray-300 { color: $gray-300 !important; }
.text-gray-400 { color: $gray-400 !important; }
.text-gray-500 { color: $gray-500 !important; }
.text-gray-600 { color: $gray-600 !important; }
.text-gray-700 { color: $gray-700 !important; }
.text-gray-800 { color: $gray-800 !important; }
.text-gray-900 { color: $gray-900 !important; }

.bg-blue { background-color: $blue !important; }
.bg-indigo { background-color: $indigo !important; }
.bg-purple { background-color: $purple !important; }
.bg-pink { background-color: $pink !important; }
.bg-red { background-color: $red !important; }
.bg-orange { background-color: $orange !important; }
.bg-yellow { background-color: $yellow !important; }
.bg-green { background-color: $green !important; }
.bg-teal { background-color: $teal !important; }
.bg-cyan { background-color: $cyan !important; }
.bg-gray { background-color: $dark-gray !important; }

.bg-gray-100 { background-color: $gray-100 !important; }
.bg-gray-200 { background-color: $gray-200 !important; }
.bg-gray-300 { background-color: $gray-300 !important; }
.bg-gray-400 { background-color: $gray-400 !important; }
.bg-gray-500 { background-color: $gray-500 !important; }
.bg-gray-600 { background-color: $gray-600 !important; }
.bg-gray-700 { background-color: $gray-700 !important; }
.bg-gray-800 { background-color: $gray-800 !important; }
.bg-gray-900 { background-color: $gray-900 !important; }

.bg-blue-light { background-color: lighten($blue, 25%) !important; }
.bg-indigo-light { background-color: lighten($indigo, 25%) !important; }
.bg-purple-light { background-color: lighten($purple, 25%) !important; }
.bg-pink-light { background-color: lighten($pink, 25%) !important; }
.bg-red-light { background-color: lighten($red, 33%) !important; }
.bg-orange-light { background-color: lighten($orange, 25%) !important; }
.bg-yellow-light { background-color: lighten($yellow, 25%) !important; }
.bg-green-light { background-color: lighten($green, 50%) !important; }
.bg-teal-light { background-color: lighten($teal, 25%) !important; }
.bg-cyan-light { background-color: lighten($cyan, 25%) !important; }

.border-blue { border-color: $blue !important; }
.border-indigo { border-color: $indigo !important; }
.border-purple { border-color: $purple !important; }
.border-pink { border-color: $pink !important; }
.border-red { border-color: $red !important; }
.border-orange { border-color: $orange !important; }
.border-yellow { border-color: $yellow !important; }
.border-green { border-color: $green !important; }
.border-teal { border-color: $teal !important; }
.border-cyan { border-color: $cyan !important; }
.border-gray { border-color: $dark-gray !important; }

.border-gray-100 { border-color: $gray-100 !important; }
.border-gray-200 { border-color: $gray-200 !important; }
.border-gray-300 { border-color: $gray-300 !important; }
.border-gray-400 { border-color: $gray-400 !important; }
.border-gray-500 { border-color: $gray-500 !important; }
.border-gray-600 { border-color: $gray-600 !important; }
.border-gray-700 { border-color: $gray-700 !important; }
.border-gray-800 { border-color: $gray-800 !important; }
.border-gray-900 { border-color: $gray-900 !important; }

