// Adapted from https://github.com/m-thalmann/overlayjs

.form-overlay{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	animation: form_overlay_fadein 0.5s;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 100;
}

.form-overlay .form-overlay-content{
	background-color: rgba(0, 0, 0, 0);
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 250px;
	max-width: 100%;
	min-height: 100px;
	max-height: 100%;
	overflow: auto;
	opacity: 1;
	padding: 0.5rem;
	animation: form_overlay_content_fadein 0.5s;
}

.form-overlay.form-overlay-closing{
  animation: form_overlay_fadeout 0.5s;
  background-color: rgba(0, 0, 0, 0);
}

.form-overlay.form-overlay-closing .form-overlay-content{
  animation: form_overlay_content_fadeout 0.5s;
}

@keyframes form_overlay_fadein {
	0%{ background-color: rgba(0, 0, 0, 0); }
	25%{ background-color: rgba(0, 0, 0, 0); }

	100%{ background-color: rgba(0, 0, 0, 0.7); }
}

@keyframes form_overlay_fadeout {
	0%{ background-color: rgba(0, 0, 0, 0.7); }

	100%{ background-color: rgba(0, 0, 0, 0); }
}

@keyframes form_overlay_content_fadein {
	0%{ opacity: 0; }
	25%{ opacity: 0; }

	100%{ opacity: 1; }
}

@keyframes form_overlay_content_fadeout {
	0%{ opacity: 1; }

	100%{ opacity: 0; }
}
