.unique-icon {
	position: relative;
	height: 1em;
	width: 1em;
	font-size: 4em;

	.fa {
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
		text-align: center;
	}

	.text {
		z-index: 1;
		position: relative;
		top: 0.25em;
		left: 0;
		font-size: 50%;
		text-align: center;
	}
}
