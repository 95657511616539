@for $i from 0 through 25 {
	.animated.delay-order-#{$i} {
		-webkit-animation-delay: 0s + (0.5 * $i);
		animation-delay: 0s + (0.5 * $i);
	}
}

@for $i from 0 through 25 {
	.animated.delay-order-fast-#{$i} {
		-webkit-animation-delay: 0s + (0.25 * $i);
		animation-delay: 0s + (0.25 * $i);
	}
}

@for $i from 0 through 40 {
	.animated.delay-order-hyper-#{$i} {
		-webkit-animation-delay: 0s + (0.1 * $i);
		animation-delay: 0s + (0.1 * $i);
	}
}

@for $i from 6 through 10 {
	.animated.delay-#{$i}s {
		-webkit-animation-delay: 0s + $i;
		animation-delay: 0s + $i;
	}
}

@for $i from 1 through 10 {
	.animated.animate-#{$i}-times {
		animation-iteration-count: $i;
	}
}

@keyframes softbounce { 
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

.softBounce {
  animation: softbounce 1s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@-webkit-keyframes pulse-lg {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse-lg {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse-lg {
  -webkit-animation-name: pulse-lg;
  animation-name: pulse-lg;
}

@-webkit-keyframes fadeInUpSm {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpSm {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp-sm {
  -webkit-animation-name: fadeInUpSm;
  animation-name: fadeInUpSm;
}

@-webkit-keyframes fadeInUpMd {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpMd {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp-md {
  -webkit-animation-name: fadeInUpMd;
  animation-name: fadeInUpMd;
}
