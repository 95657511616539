@use "../guidepost" as guidepost;

.form-step {
	@extend .rounded;
	@extend .border;
	@extend .bg-light;
	@extend .py-1;
	@extend .px-2;

	.form-step-header {
		@extend .py-1;
	}

	.form-step-content {
		@extend .bg-light;
	}

	.form-step-content.hidden {
		display: none;
	}

}
