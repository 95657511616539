@use "../guidepost";

.input-with-icon {
	position: relative !important;

	input {
		padding-left: 40px;
		padding-right: 20px;
	}

	.input-icon {
		position: absolute;
		z-index: 9;
		line-height: 38px;
		left: 10px;
		top: 0;
	}
}

.input-with-right-icon {
	position: relative !important;

	input {
		padding-right: 25px;
	}

	.input-icon {
		@extend .px-2;
		position: absolute;
		z-index: 9;
		line-height: 38px;
		right: 6px;
		top: 0;
	}
}
