@use "../guidepost" as guidepost;
@use "../layouts";

.timeline-container {
	@extend .rounded;
	@extend .shadow;
	@extend .no-user-select;

	width: 100%;
	min-height: 362px;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
}

.timeline-container .timeline-header {
	@extend .bg-light;
	height: 32px;
}

.timeline-container .month {
	@extend .fs-4;
	z-index: 60;
	position: absolute;
	top: -34px;
	letter-spacing: 12px;
}

.timeline {
	@extend .bg-white;
	@extend .d-inline-flex;
	@extend .flex-row;
	@extend .flex-nowrap;
	white-space: nowrap;
	position: relative;
}

.timeline .day, .timeline .day .events {
	@extend .d-inline-flex;
	@extend .flex-column;
	@extend .gap-2;
}

.timeline .day {
	@extend .border-start;
	@extend .px-1;
	@extend .py-2;

	min-height: 320px;
	//min-width: 106px;
	//width: 5%;

	.day-header {
		@extend .fs-2;
		@extend .no-user-select;
		@extend .text-center;

		.day-name {
			@extend .ms-2;
			@extend .fs-7;
			@extend .text-gray-500;

			display: inline-block;
		}
	}
}

.timeline .day:hover {
	background-color: hsl(192, 100%, 92%);
}

.timeline .day .events .event-spacer {
	visibility: hidden;
	position: relative;
	left: 0;
	height: 50px;
}

.timeline .day .events .event {
	z-index: 50;
	position: relative;
	left: 0;
	height: 50px;

	.event-start-icon {
		z-index: 60;
		position: absolute;
		top: 10px;
		left: 0;
		font-size: 20px;
	}

	.event-end-icon {
		z-index: 60;
		position: absolute;
		top: 10px;
		right: 3px;
		font-size: 20px;
	}

	.event-icon {
		visibility: hidden;
		z-index: 60;
		position: absolute;
		top: 3px;
		left: 0;
		font-size: 11px;
	}

	.event-label {
		@extend .shadow-sm;
		@extend .rounded;
		@extend .d-flex;
		@extend .flex-column;
		@extend .px-1;
		@extend .text-center;
		@extend .clickable;

		z-index: 55;
		position: absolute;
		top: 0;
		left: 0;
		//width: 98px;
		height: 50px;
		padding-top: 3px;
		overflow-x: hidden;
		//opacity: 0.5;

		.event-user-name {
			white-space: nowrap;
			overflow: hidden;
			//text-overflow: ellipsis;
		}

		.event-absence-type {
			@extend .text-gray-800;
			font-size: 0.80rem;
		}
	}

}

.event-label:hover {
	filter: brightness(82.5%);
}

.timeline .day .event.placeholder {
	visibility: hidden;
}
